import React from "react"

import { Button, Input, Label, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { ClipLoader } from "react-spinners"
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"

// Hooks
import { useAuth } from "../../hooks/useAuth.js"

// Controllers
import { QuestionController } from "../../controllers/QuestionController.js"

// Modals
import NewQuestionModal from "../modals/new-question.jsx"

// Assets
import "./questions.scss"
import logoIcon from "../../assets/images/icon.png"
import { questionLevels } from "../../constants/questions.js"

const columnHelper = createColumnHelper()

export default function QuestionsPage() {
  const columns = [
    columnHelper.accessor((row) => row?.questionId, {
      id: "questionId",
      cell: (info) => info.getValue(),
      header: () => <span>Código</span>,
    }),
    columnHelper.accessor((row) => row.questionName, {
      id: "questionName",
      cell: (info) => info.getValue(),
      header: () => <span>Pergunta</span>,
    }),
    columnHelper.accessor((row) => row.questionLevel.levelName, {
      id: "levelName",
      cell: (info) => info.getValue(),
      header: () => <span>Nível</span>,
    }),
    columnHelper.accessor((row) => row.questionAlternatives[0].alternativeName, {
      id: "answer",
      cell: (info) => info.getValue(),
      header: () => <span>Resposta</span>,
    }),
    columnHelper.accessor((row) => row.questionAlternatives[1].alternativeName, {
      id: "alternative1",
      cell: (info) => info.getValue(),
      header: () => <span>Alternativa 1</span>,
    }),
    columnHelper.accessor((row) => row.questionAlternatives[2].alternativeName, {
      id: "alternative2",
      cell: (info) => info.getValue(),
      header: () => <span>Alternativa 2</span>,
    }),
    columnHelper.accessor((row) => row.questionAlternatives[3].alternativeName, {
      id: "alternative3",
      cell: (info) => info.getValue(),
      header: () => <span>Alternativa 3</span>,
    }),
    columnHelper.accessor((row) => row.questionAlternatives[4].alternativeName, {
      id: "alternative4",
      cell: (info) => info.getValue(),
      header: () => <span>Alternativa 4</span>,
    }),
    columnHelper.accessor((row) => row.questionRef, {
      id: "questionRef",
      cell: (info) => info.getValue(),
      header: () => <span>Referência</span>,
    }),
    columnHelper.accessor((row) => row.questionTestamentId, {
      id: "questionTestament",
      cell: (info) => {
        return !info.getValue()
          ? "-"
          : info.getValue() === 1
            ? "Antigo Testamento"
            : "Novo Testamento"
      },
      header: () => <span>Testamento</span>,
    }),
    columnHelper.accessor((row) => row.questionClass.questionClassName, {
      id: "questionClassName",
      cell: (info) => {
        return info.getValue() === "Geral" ? "-" : info.getValue()
      },
      header: () => <span>Classificação</span>,
    }),
    columnHelper.accessor((row) => row.questionCategory.questionCategoryName, {
      id: "questionCategoryName",
      cell: (info) => info.getValue(),
      header: () => <span>Categoria</span>,
    }),
    // columnHelper.accessor((row) => row.questionCreatedUserId, {
    //   id: "questionCreatedUserId",
    //   cell: (info) => info.getValue(),
    //   header: () => <span>Criado por</span>,
    // }),
    // columnHelper.accessor((row) => row.questionUpdatedUserId, {
    //   id: "questionUpdatedUserId",
    //   cell: (info) => info.getValue(),
    //   header: () => <span>Atualizado por</span>,
    // }),
  ]

  const { user, signOut } = useAuth()

  const [, setLoadingQuestion] = React.useState(false)

  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 100,
    pages: [],
  })

  const [searchValue, setSearchValue] = React.useState("")
  const [questionModal, setQuestionModal] = React.useState({ status: false, content: null })

  const [questions, setQuestions] = React.useState([])
  const [selectedLevels, setSelectedLevels] = React.useState([])
  const [questionClasses, setQuestionClasses] = React.useState([])
  const [questionCategories, setQuestionCategories] = React.useState([])

  const [loadingTable, setLoadingTable] = React.useState(false)

  const table = useReactTable({
    data: questions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  React.useEffect(() => {
    if (user?.userId) {
      onLoadQuestionResources()
      getQuestions({ pageSize: 100 })
      setSelectedLevels([])
    } else {
      signOut()
    }
  }, [user])

  const onSaveQuestion = (question) => {
    if (question?.questionName) {
      if (question?.questionAlternatives?.length > 0) {
        if (question?.questionLevelId) {
          setLoadingQuestion(true)

          if (question?.questionId) {
            QuestionController.updateQuestion(question?.questionId, {
              ...question,
              questionUpdatedUserId: user?.playerId,
            })
              .then((response) => {
                if (response.status === 200) {
                  const questionIndex = questions.findIndex(
                    ({ questionId }) => questionId === question?.questionId,
                  )
                  if (questionIndex !== -1) {
                    questions[questionIndex] = {
                      ...questions[questionIndex],
                      ...question,
                      questionLevel: questionLevels.find(
                        ({ levelId }) => levelId === question.questionLevelId,
                      ),
                      questionClass: questionClasses.find(
                        ({ questionClassId }) => questionClassId === question.questionClassId,
                      ),
                      questionCategory: questionCategories.find(
                        ({ questionCategoryId }) =>
                          questionCategoryId === question.questionCategoryId,
                      ),
                    }
                    setQuestions([...questions])
                    setQuestionModal({ status: false, content: null })
                  }
                } else {
                  alert("Erro ao atualizar a pergunta (1)")
                }
              })
              .catch((error) => {
                console.error("Erro ao atualizar a pergunta", error)
                alert("Erro ao atualizar a pergunta (2)")
              })
              .finally(() => {
                setLoadingQuestion(false)
              })
          } else {
            QuestionController.createQuestion({
              ...question,
              questionCreatedUserId: user?.playerId,
            })
              .then((response) => {
                if (response.status === 201) {
                  if (response?.content) {
                    setQuestions([
                      ...questions,
                      {
                        questionId: response?.content?.questionId,
                        ...question,
                        questionLevel: questionLevels.find(
                          ({ levelId }) => levelId === question.questionLevelId,
                        ),
                        questionClass: questionClasses.find(
                          ({ questionClassId }) => questionClassId === question.questionClassId,
                        ),
                        questionCategory: questionCategories.find(
                          ({ questionCategoryId }) =>
                            questionCategoryId === question.questionCategoryId,
                        ),
                      },
                    ])

                    setQuestionModal({ status: false, content: null })
                  }
                } else {
                  alert("Erro ao criar a pergunta (1)")
                }
              })
              .catch((error) => {
                console.error("Erro ao criar a pergunta", error)
                alert("Erro ao criar a pergunta (2)")
              })
              .finally(() => {
                setLoadingQuestion(false)
              })
          }
        } else {
          alert("Faltou informar o nível da pergunta!")
        }
      } else {
        alert("Faltou informar as alternativas da pergunta!")
      }
    } else {
      alert("Faltou informar o título da pergunta!")
    }
  }

  const onUpdateQuestion = (questionId) => {
    setQuestionModal({ status: true, content: { questionId } })
  }

  const onSelectedLevel = (levelId) => {
    const levelIndex = selectedLevels.findIndex((selectedLevelId) => selectedLevelId === levelId)

    if (levelIndex !== -1) {
      selectedLevels.splice(levelIndex, 1)
      setSelectedLevels([...selectedLevels])
      getQuestions({ searchValue, levels: selectedLevels, pageSize: 100 })
    } else {
      setSelectedLevels([...selectedLevels, levelId])
      getQuestions({ searchValue, levels: [...selectedLevels, levelId], pageSize: 100 })
    }
  }

  const onSearch = (event) => {
    const value = event.target.value || ""

    setSearchValue(value)

    if (value === "") {
      getQuestions({ searchValue: value, levels: selectedLevels, pageSize: 100 })
    } else if (value.length > 2) {
      getQuestions({ searchValue: value, levels: selectedLevels })
    }
  }

  const onBuildPagination = (total, currentPage) => {
    const pages = []

    if (total <= pagination.pageSize) {
      setPagination({ ...pagination, totalPages: 1, pages: [1] })
      return
    }

    const totalPages = Math.ceil(total / 100)

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i)
    }

    setPagination({ ...pagination, currentPage, totalPages, pages })
  }

  const onLoadQuestionResources = async () => {
    // Get question classes and question categories
    const [questionClasses, questionCategories] = await Promise.all([
      (async () => {
        return new Promise((resolve) => {
          getQuestionClasses().then((response) => {
            resolve(response)
          })
        })
      })(),
      (async () => {
        return new Promise((resolve) => {
          getQuestionCategories().then((response) => {
            resolve(response)
          })
        })
      })(),
    ])

    setQuestionClasses(questionClasses)
    setQuestionCategories(questionCategories)
  }

  const handlePageChange = (currentPage) => {
    getQuestions({
      searchValue,
      levels: selectedLevels,
      pageSize: 100,
      pageNumber: currentPage - 1,
    })
  }

  const getQuestions = (filter = {}) => {
    setLoadingTable(true)

    QuestionController.getAllQuestions(filter)
      .then((response) => {
        if (response.status === 200) {
          const currentPage = (filter?.pageNumber || 0) + 1
          onBuildPagination(response.content.totalRecords, currentPage)
          setQuestions(response.content.questions || [])
        } else {
          alert("Erro ao consultar as perguntas (1)")
        }
      })
      .catch((error) => {
        console.error("Erro ao consultar as perguntas", error)
        alert("Erro ao consultar as perguntas (2)")
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  const getQuestionClasses = async () => {
    const questionClasses = JSON.parse(sessionStorage.getItem("questionClasses"))

    if (!questionClasses) {
      return await QuestionController.getAllQuestionClass({})
        .then((response) => {
          if (response.status === 200 && response?.content) {
            const questionClasses = response.content.questionClasses || []
            sessionStorage.setItem("questionClasses", JSON.stringify(questionClasses))

            return questionClasses
          }
          return []
        })
        .catch(() => {
          return []
        })
    }
    return questionClasses
  }

  const getQuestionCategories = async () => {
    const questionCategories = JSON.parse(sessionStorage.getItem("questionCategories"))

    if (!questionCategories) {
      return await QuestionController.getAllQuestionCategory({})
        .then((response) => {
          if (response.status === 200 && response?.content) {
            const questionCategories = response.content.questionCategories || []

            sessionStorage.setItem("questionCategories", JSON.stringify(questionCategories))

            return questionCategories
          }
          return []
        })
        .catch(() => {
          return []
        })
    }
    return questionCategories
  }

  return (
    <div className="app">
      <div className="app-left" style={{ width: 120 }}>
        <div className="app-left--image">
          <img alt="Logo" src={logoIcon} />
        </div>
        <Sidebar style={{ minWidth: 120, width: 120 }}>
          <Menu>
            <MenuItem style={{ width: 120 }}> Perguntas </MenuItem>
            {/* <MenuItem style={{ width: 120 }}>
              <NavLink to="/crosswords">Diretas</NavLink>
            </MenuItem> */}
            <MenuItem style={{ width: 120 }} onClick={signOut}>
              Sair
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>

      <div className="app-right">
        <div className="app-right--header">
          <h4>Lista de Perguntas</h4>
          <div className="app-right--header--filters">
            <Input placeholder="Procurar ..." value={searchValue} onChange={onSearch} />
            <Button outline={!selectedLevels.includes(1)} onClick={() => onSelectedLevel(1)}>
              Fácil
            </Button>
            <Button outline={!selectedLevels.includes(2)} onClick={() => onSelectedLevel(2)}>
              Médio
            </Button>
            <Button outline={!selectedLevels.includes(3)} onClick={() => onSelectedLevel(3)}>
              Difícil
            </Button>
          </div>
          <h6>
            Número de Perguntas: <strong>{questions.length}</strong>
          </h6>
          <Button
            className="app-right--header--add-button"
            onClick={() => setQuestionModal({ status: true, content: null })}
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;Nova Pergunta
          </Button>
        </div>
        <div className="app-right--body">
          <div>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} onClick={() => onUpdateQuestion(row?.original?.questionId)}>
                      {row.getVisibleCells().map((cell) => {
                        const cellId = cell.id.replace(row.id + "_", "")

                        let className = ""

                        if (cellId.indexOf("questionName") !== -1) {
                          className = cell.getValue().length > 100 ? "td-long-text" : ""
                        } else if (cellId.indexOf("alternative") !== -1) {
                          className = cell.getValue().length > 30 ? "td-long-text" : ""
                        }

                        return (
                          <td
                            key={cell.id}
                            style={{ textAlign: cellId === "questionId" ? "center" : "left" }}
                            className={className}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="app-right--footer">
          <Pagination>
            <PaginationItem disabled>
              <PaginationLink first href="#" />
            </PaginationItem>
            <PaginationItem disabled>
              <PaginationLink href="#" previous />
            </PaginationItem>
            {pagination.pages.map((page) => (
              <PaginationItem key={page} active={pagination.currentPage === page}>
                <PaginationLink href="#" onClick={() => handlePageChange(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled>
              <PaginationLink href="#" next />
            </PaginationItem>
            <PaginationItem disabled>
              <PaginationLink href="#" last />
            </PaginationItem>
          </Pagination>
        </div>

        {loadingTable ? (
          <div className="app-right--loading">
            <ClipLoader color={"#50526a"} loading={true} size={100} />
            <Label>Carregando as perguntas...</Label>
          </div>
        ) : (
          ""
        )}
      </div>

      <NewQuestionModal
        status={questionModal.status}
        content={questionModal.content}
        onClose={() => setQuestionModal({ status: false, content: null })}
        onConfirm={onSaveQuestion}
        questionClasses={questionClasses}
        questionCategories={questionCategories}
      />
    </div>
  )
}
