import React from "react"
import { Routes, Route } from "react-router-dom"

import { AuthProvider } from "./hooks/useAuth.js"

import { ProtectedRoute } from "./components/ProtectedRoute.js"

import SignInPage from "./views/pages/signin.jsx"
import ShareAppPage from "./views/pages/share-app.jsx"
import QuestionsPage from "./views/pages/questions.jsx"
import CrosswordsPage from "./views/pages/crosswords.jsx"
import RemovePlayerPage from "./views/pages/remove-player.jsx"
import PrivacyPolicyPage from "./views/pages/privacy-policy.jsx"

import "./App.scss"

import "./assets/fonts/Barlow-Thin.ttf"
import "./assets/fonts/Barlow-ExtraLight.ttf"
import "./assets/fonts/Barlow-Light.ttf"
import "./assets/fonts/Barlow-Regular.ttf"
import "./assets/fonts/Barlow-Medium.ttf"
import "./assets/fonts/Barlow-SemiBold.ttf"
import "./assets/fonts/Barlow-Bold.ttf"
import "./assets/fonts/Barlow-Black.ttf"
import "./assets/fonts/Barlow-ExtraBold.ttf"

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <QuestionsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/crosswords" element={<CrosswordsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/remove-player" element={<RemovePlayerPage />} />
        <Route path="/remove-player" element={<RemovePlayerPage />} />
        <Route path="/share" element={<ShareAppPage />} />
      </Routes>
    </AuthProvider>
  )
}

export default App
