/* eslint-disable react/prop-types */
import React, { createContext, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "./useLocalStorage.js"
import { AuthController } from "../controllers/AuthController.js"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null)
  const navigate = useNavigate()

  React.useEffect(() => {
    checkUser()
  }, [])

  // call this function when you want to authenticate the user
  const signIn = async (username, password) => {
    AuthController.signIn(username.trim().toLowerCase(), password)
      .then((response) => {
        if (response.status === 200) {
          setUser(response?.content)
          navigate("/")
        } else {
          alert(response.message)
        }
      })
      .catch(() => {
        alert("Erro ao validar credenciais do usuário")
      })
  }

  const checkUser = async () => {
    const userSession = localStorage.getItem("user")
    if (userSession) {
      const user = JSON.parse(userSession)
      setUser(user)

      if (user?.userId) {
        AuthController.checkUser(user?.userId).then((response) => {
          if (response.status === 200) {
            const isUserExists = response?.content
            if (!isUserExists) {
              signOut()
            }
          } else {
            signOut()
          }
        })
      }
    }
  }

  // call this function to sign out logged in user
  const signOut = () => {
    setUser(null)
    navigate("/", { replace: true })
  }

  const value = useMemo(() => ({ user, signIn, signOut, setUser }), [user])
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
