import React from "react"

import { Label } from "reactstrap"

import "./share-app.scss"
import logoIcon from "../../assets/images/icon.png"

export default function ShareApp() {
  document.title = "Bíblia Play"

  const metaDescription = document.querySelector("meta[name='description']")
  if (metaDescription) {
    metaDescription.setAttribute("content", "Quem joga, aprende. Quem aprende, cresce!")
  }

  React.useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    const isAndroid = /Android/i.test(navigator.userAgent)

    if (isIOS) {
      console.log("Running on iOS")
      window.location.href = "https://apps.apple.com/br/app/b%C3%ADblia-play/id6711335870"
    } else if (isAndroid) {
      console.log("Running on Android")
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.brainwave.bibliaplay"
    }

    setTimeout(() => {
      window.history.back()
    }, 5000)
  }, [])

  return (
    <div className="share-app">
      <div className="share-app--image">
        <img alt="Logo" src={logoIcon} />
      </div>
      <Label className="share-app--label">Redirecionando para a loja...</Label>
    </div>
  )
}
