import React from "react"

import { Input, Label } from "reactstrap"

import "./remove-player.scss"
import logoIcon from "../../assets/images/logo.png"
import { PlayerController } from "../../controllers/PlayerController"

export default function RemovePlayer() {
  const [userLogin, setUserLogin] = React.useState("")
  const [userPassword, setUserPassword] = React.useState("")

  const [responseDeleted, setResponseDeleted] = React.useState({
    status: null,
    message: null,
  })

  const onChangeLogin = (e) => {
    setUserLogin(e.target.value)
  }

  const onChangePassword = (e) => {
    setUserPassword(e.target.value)
  }

  const onDeletePlayer = () => {
    if (!userLogin || !userPassword) {
      alert("Preencha os campos corretamente")
      return
    }

    PlayerController.deletePlayer(userLogin, userPassword)
      .then((response) => {
        if (response.status === 200) {
          setResponseDeleted({
            status: 200,
            message: "Jogador excluído com sucesso!",
          })
        } else if (response.status === 400) {
          setResponseDeleted({
            status: 400,
            message: "Erro ao excluir jogador, verifique os dados informados!",
          })
        } else {
          setResponseDeleted({ status: 500, message: "Erro ao excluir jogador" })
        }
      })
      .catch(() => {
        setResponseDeleted({ status: 500, message: "Erro ao excluir jogador" })
      })
  }

  return (
    <div className="remove-player">
      <div className="remove-player--body">
        <div className="remove-player--body--image">
          <img alt="Logo" src={logoIcon} />
        </div>
        <h2>
          <strong>Solicitação de exclusão de jogador</strong>
        </h2>
        <div className="remove-player--body--info">
          <Label>Informe seu nome de usuário ou email</Label>
          <Input
            value={userLogin}
            placeholder={"Nome de usuário ou email"}
            onChange={onChangeLogin}
          />
          <Label>Informe sua senha</Label>
          <Input
            value={userPassword}
            placeholder={"Senha"}
            onChange={onChangePassword}
            type="password"
          />

          <span style={{ color: responseDeleted.status === 200 ? "#00bc00" : "#FF0000" }}>
            {responseDeleted.message}
          </span>

          {responseDeleted.status === 200 && (
            <span>
              Seus dados ficarão em nossa base por 30 dias, depois disso serão excluídos
              permanentemente!
            </span>
          )}
          <button className="remove-player--button" onClick={onDeletePlayer}>
            Solicitar exclusão
          </button>
        </div>
      </div>
    </div>
  )
}
