import { encrypt } from "../helpers/MethodTools.js"
import callRequest from "../helpers/RequestTools.js"

export const AuthController = {
  signIn: (username, password) => {
    const content = { username, password: encrypt(password) }
    return callRequest(`/auth`, content, "POST")
  },

  checkUser: (userId) => {
    return callRequest(`/user/check/exists`, { userId }, "GET")
  },

  getLastVersion: () => {
    return callRequest(`/auth/last/version`, { projectName: "adm" }, "GET")
  },
}
